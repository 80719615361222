import ApiKeys from '@bb/utils/lib/ApiKeys';

const API_KEY = ApiKeys.KEYS.bpblanding
const HEADER_API_KEY = ApiKeys.HEADER_API_KEY

export default class Api {
    static get(url) {
        return fetch(`/api/v1/${url}`, {
            method: 'GET',
            headers: {
                [HEADER_API_KEY]: API_KEY,
                // 'Content-Type': 'application/json',
                "accepts": "application/json"
            },
        })
    }
    static post(url, payload) {
        return fetch(`/api/v1/${url}`, {
            method: 'POST',
            headers: {
                [HEADER_API_KEY]: API_KEY,
                'Content-Type': 'application/json',
                "accepts": "application/json"
            },
            body: JSON.stringify(payload),
        })
    }
}