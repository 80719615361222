<template>
  <div
    class="modal fade"
    id="cookieAcceptModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg"
      style="align-items: flex-end"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-12 text-center text-md-left">
                <div class="content mb-4">
                  {{ $t("cookies.acceptCookies.content") }}
                </div>
                <div class="link mb-md-0 mb-4">
                  {{ $t("cookies.acceptCookies.link") }}
                </div>
              </div>
              <div
                class="
                  col-md-6 col-12
                  d-flex
                  align-items-center
                  justify-content-between justify-content-md-end
                "
              >
                <base-button
                  class="modal-button mr-md-5"
                  :text="$t('cookies.acceptCookies.cookieSettings')"
                  size="large"
                  type="secondary"
                  @clicked="emitShowSettings()"
                ></base-button>
                <base-button
                  class="modal-button"
                  :text="$t('cookies.acceptCookies.accept')"
                  size="large"
                  @clicked="acceptCookies()"
                ></base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "../../elements/Button.vue";
export default {
  name: "CookieAcceptModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: { BaseButton },
  data: () => ({}),
  mounted() {
    this.$eventBus.$on("openCookieSettingsModal", () => {
      this.$emit("onShowSettings");
    });

    if (this.show) {
      $("#cookieAcceptModal").modal("show");
    } else {
      $("#cookieAcceptModal").modal("hide");
    }
  },
  watch: {
    show: function () {
      if (this.show) {
        $("#cookieAcceptModal").modal("show");
      } else {
        $("#cookieAcceptModal").modal("hide");
      }
    },
  },
  computed: {},
  methods: {
    acceptCookies: function () {
      this.$emit("onAcceptCookies");
    },
    emitShowSettings: function () {
      this.$emit("onShowSettings");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "bootstrap";

#cookieAcceptModal {
  top: auto;
  bottom: 0;
  padding-right: 0 !important;
}

.modal-dialog {
  min-height: 0;

  @media (min-width: map_get($grid-breakpoints, "sm")) {
    max-width: map_get($grid-breakpoints, "sm") !important;
  }

  @media (min-width: map_get($grid-breakpoints, "md")) {
    max-width: map_get($grid-breakpoints, "md") !important;
  }

  @media (min-width: map_get($grid-breakpoints, "lg")) {
    max-width: map_get($grid-breakpoints, "lg") !important;
  }

  @media (min-width: map_get($grid-breakpoints, "xl")) {
    max-width: map_get($grid-breakpoints, "xl") !important;
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: $navbar-dropdown-shadow;

    .content {
      margin-bottom: 10px;
    }
  }
}
</style>