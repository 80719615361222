<template>
  <div id="footer">
    <div class="container">
      <div class="row first-row">
        <div class="col-lg-5 col-12">
          <img
            class="blockben-icon"
            width="200px"
            src="/images/logos/bb-logo-wtext-white.svg"
          />
        </div>
        <div class="col-lg-2 col-3">
          <div class="title">{{ $t("footer.company") }}</div>
          <router-link class="link" to="/about-us">{{ $t("globals.navigation.aboutUs") }}</router-link>
          <a class="link" href="https://blog.blockben.com/">{{ $t("globals.navigation.blog") }}</a>
        </div>
        <div class="col-lg-3 col-8">
          <div class="title">{{ $t("footer.help") }}</div>
          <a href="mailto:support@blockben.com" class="link">{{ $t("globals.navigation.contact") }}</a>
        </div>
        <div class="col-lg-2 col-12">
          <div class="title">{{ $t("footer.follow") }}</div>
          <div class="icons">
            <a href="https://www.facebook.com/blockbenofficial">
              <i class="bb-facebook icon" />
            </a>
            <a href="https://twitter.com/blockben?lang=en">
              <img src="/images/icons/x.svg" class="icon"/>
            </a>
            <a href="https://www.linkedin.com/company/blockben/">
              <i class="bb-linkedin icon" />
            </a>
            <a href="https://www.youtube.com/channel/UC9SB-B1Gf50SVnerTLGIMyA">
              <i class="bb-youtube icon" />
            </a>
          </div>
        </div>
      </div>
      <div class="row second-row">
        <div class="col-lg-6 d-none d-sm-block">
          <div class="legal-notice">
            {{ $t("footer.legalNotice") }}
          </div>
        </div>
        <div class="col-lg-6 d-none d-lg-block d-xl-block">
          <div class="legal-links">
            <p class="legal-link">
              <router-link to="/legal-notice">{{
                $t("footer.legal.disclaimer")
              }}</router-link>
            </p>
            <p class="legal-link">
              <a
                :href="`${
                  $i18n.locale === 'hu'
                    ? 'https://data.blockben.com/terms/A_BlockWallet_GTC_hu.pdf'
                    : 'https://data.blockben.com/terms/A_BlockWallet_GTC_en.pdf'
                }`"
                target="_blank"
              >
                {{ $t("footer.legal.generalTermsAndConditions") }}
              </a>
            </p>
            <p class="legal-link">
              <a
                :href="`${
                  $i18n.locale === 'hu'
                    ? 'https://data.blockben.com/gdpr/Privacy_Policy_hu.pdf'
                    : 'https://data.blockben.com/gdpr/Privacy_Policy_en.pdf'
                }`"
                target="_blank"
              >
                {{ $t("footer.legal.dataProtection") }}
              </a>
            </p>
            <p class="legal-link">
              <a
                :href="`${
                  $i18n.locale === 'hu'
                    ? 'https://data.blockben.com/terms/F_Anti_Money_Laundering_extract_en.pdf'
                    : 'https://data.blockben.com/terms/F_Anti_Money_Laundering_extract_en.pdf'
                }`"
                target="_blank"
              >
                {{ $t("footer.legal.aml") }}
              </a>
            </p>
            <p class="legal-link cookie-settings-btn" @click="openCookieSettingsModal">{{ $t("footer.legal.cookieSettings") }}</p>
          </div>
        </div>

        <div class="col-12 d-sm-none">
          <router-link class="legal-link-xs" to="/legal-notice">{{ $t("footer.legal.disclaimer") }}</router-link>
          <a :href="`https://data.blockben.com/terms/A_BlockWallet_GTC_${$i18n.locale}.pdf`" target="_blank" class="legal-link-xs margin-top-s">
            {{ $t("footer.legal.generalTermsAndConditions") }}
          </a>
          <a :href="`https://data.blockben.com/gdpr/Privacy_Policy_${$i18n.locale}.pdf`" target="_blank" class="legal-link-xs margin-top-s">
            {{ $t("footer.legal.dataProtection") }}
          </a>
          <a :href="`https://data.blockben.com/terms/F_Anti_Money_Laundering_extract_${$i18n.locale}.pdf`" target="_blank" class="legal-link-xs margin-top-s">
            {{ $t("footer.legal.aml") }}
          </a>
          <p class="legal-link-xs margin-top-s" @click="openCookieSettingsModal">
            {{ $t("footer.legal.cookieSettings") }}
          </p>
        </div>
        <div class="col-12 d-sm-none">
          <div class="legal-notice-xs">{{ $t("footer.legalNotice") }}</div>
        </div>
      </div>
      <div class="row third-row">
        <div class="company-info">{{ $t("footer.companyInfo") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    openCookieSettingsModal() {
      this.$eventBus.$emit("openCookieSettingsModal");
    }
  },
};
</script>
 
<style lang='scss' scoped>
@import "@/assets/scss/_fonts.scss";
@import "@/assets/scss/_colors.scss";

#footer {
  background-color: $black;
}

.first-row {
  padding-bottom: $margin-m;
  border-bottom: 2px solid rgba($white, 0.4);
}

.blockben-icon {
  padding-top: $margin-m;
}
.title {
  padding-top: $margin-m;
  font-size: $text-s;
  font-family: "HKNova-Bold";
  color: $white;
}
.link {
  font-size: $text-xs;
  color: $white;
  margin-top: $margin-s;
  display: block;
}

.icons {
  margin-top: $margin-s;
  line-height: $text-m;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $breakpoint-md) {
    justify-content: flex-start;
  }
}

.icon {
  color: $white;
  font-size: $text-m;

  @media (max-width: $breakpoint-md) {
    margin-right: 35px;
  }
}

.second-row {
  padding-top: $margin-m;
  padding-bottom: $margin-m;
  border-bottom: 2px solid rgba($white, 0.4);
}

.third-row {
  padding-top: $margin-m;
  padding-bottom: $margin-m;
  text-align: center;
}
.legal-notice {
  color: $white;
  font-size: $text-xs;
}

.legal-notice-xs {
  color: $white;
  font-size: $text-xs;
  text-align: center;
  margin-top: $margin-s;
}

a,
a:visited {
  font-family: "HKNova", sans-serif;
  font-weight: 400;
  color: $white;
}

.legal-link {
  float: left;
  font-size: $text-xs;
  font-family: "HKNova", sans-serif;

  & a {
    color: $white !important;
  }
}

.legal-link-xs {
  font-size: $text-xs;
  color: $white;
  display: block;
  font-family: "HKNova", sans-serif;
}

.margin-top-s {
  margin-top: $margin-s;
}

.legal-links {
  display: flex !important;
  justify-content: space-between;
}

.company-info {
  font-size: $text-xs;
  color: $white;
}

.cookie-settings-btn {
  cursor: pointer;
  color: $white;
}
</style>
