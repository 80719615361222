import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";
import enums from "@/components/mixins/enums";
import helpers from '@/helpers/globalHelpers'

// screens
const HomeScreen = () => import('@/screens/HomeScreen');
const PricesScreen = () => import('@/screens/PricesScreen');
const WalletScreen = () => import('@/screens/WalletScreen');
const AboutUsScreen = () => import('@/screens/AboutUsScreen');
const SupportScreen = () => import('@/screens/SupportScreen');
const EbsoScreen = () => import('@/screens/products/EbsoScreen');
const BnoScreen = () => import('@/screens/products/BnoScreen');
const BnoxScreen = () => import('@/screens/products/BnoxScreen');
const HoloverzScreen = () => import('@/screens/products/HoloverzScreen');
const HoloverzCollectionsScreen = () => import('@/screens/HoloverzCollectionsScreen');
const LegalNoticeScreen = () => import('@/screens/LegalNoticeScreen');
const JoinPlatformScreen = () => import('@/screens/JoinPlatformScreen');


Vue.use(VueRouter);

let routes = [
  {
    path: `/:lang(${enums.supportedLanguages.map(lang => lang.value).join('|')})`,
    component: {
      render(h) { return h('router-view'); }
    },
    children: [
      {
        path: '',
        name: 'HomeScreen',
        component: HomeScreen,
      },
      {
        path: 'prices',
        name: 'PricesScreen',
        component: PricesScreen,
      },
      {
        path: 'wallet',
        name: 'WalletScreen',
        component: WalletScreen,
      },
      {
        path: 'about-us',
        name: 'AboutUsScreen',
        component: AboutUsScreen,
      },
      {
        path: 'support',
        name: 'SupportScreen',
        component: SupportScreen,
      },
      {
        path: 'products/ebso',
        name: 'EbsoScreen',
        component: EbsoScreen,
        meta: {
          assetName: 'BSO',
        }
      },
      {
        path: 'products/bno',
        name: 'BnoScreen',
        component: BnoScreen,
        meta: {
          assetName: 'BNO',
        }
      },
      {
        path: 'products/bnox',
        name: 'BnoxScreen',
        component: BnoxScreen,
        meta: {
          assetName: 'BNO',
        }
      },
      {
        path: 'products/holoverz',
        name: 'HoloverzScreen',
        component: HoloverzScreen,
        meta: {
          assetName: 'Holoverz',
        }
      },
      {
        path: 'legal-notice',
        name: 'LegalNotice',
        component: LegalNoticeScreen,
      },
      {
        path: 'holoverz-collections',
        name: 'HoloverzCollections',
        component: HoloverzCollectionsScreen,
      },
      {
        path: 'joinplatform',
        name: 'JoinPlatform',
        component: JoinPlatformScreen,
      }
    ]
  }
];

// !! this also affects the navbar
routes[0].children = routes[0].children.filter(item => {
  if (item.meta && item.meta.assetName) {
    if (helpers.getWhitelistedAssets().includes(item.meta.assetName)) {
      return item
    }
  } else {
    return item
  }
})

const router = new VueRouter({
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const validateLangParam = (lang) => {
  return enums.supportedLanguages.map(lang => lang.value).includes(lang)
}

const setSelectedLanguage = store._mutations['settings/SET_SELECTED_LANGUAGE'][0]

router.beforeEach((to, from, next) => {
  const hasLang = to.params && to.params.lang;
  const hadLang = from.params && from.params.lang;

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 350);

  if (hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase()) {
    next();
  }

  let lang
  if (hasLang) {
    lang = to.params.lang;
  } else if (hadLang) {
    lang = from.params.lang;
  } else {
    lang  = process.env.VUE_APP_I18N_FALLBACK_LANG_CODE;
  }

  if(!['hu', 'hu-HU', 'hu_HU', 'hun'].includes(navigator.language) && !hasLang && !hadLang){
    lang = "en";
  }

  setSelectedLanguage(lang);

  if (!hasLang) {
    // handle non existing lang param
    const fullPathFirstParam = to.fullPath.split('/')[1];
    if (fullPathFirstParam && fullPathFirstParam.length == 2 && validateLangParam(fullPathFirstParam)) {
      return next('/');
    }
    return next(`/${lang}${to.fullPath}`);
  }

  return next();
});

export default router;
