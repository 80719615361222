import Vue from 'vue';
import VueI18n from 'vue-i18n';

// languages
import hu from './languages/hu.json';
import en from './languages/en.json';

Vue.use(VueI18n);

// TODO: get lang from store (store.getters.langCode)
const locale = process.env.VUE_APP_I18N_FALLBACK_LANG_CODE;

const messages = {
  hu,
  en,
};

const i18n = new VueI18n({
  locale,
  messages,
});

export default i18n;
