<template>
  <div
    class="modal fade"
    id="cookieSettings"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg align-items-center"
      style="align-items: flex-end"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-2 mb-md-4 mt-2">
                <h1 class="headline">
                  {{ $t("cookies.cookieSettings.title") }}
                </h1>
              </div>
              <div class="col-12 text">
                <p>{{ $t("cookies.cookieSettings.content") }}</p>
              </div>
              <div class="col-12">
                <hr style="border: 1px solid rgba(75, 46, 170, 0.2)" />
              </div>
              <div class="col-12">
                <div class="row headline-row">
                  <div class="col-8 cursor-pointer" @click="toggleFunctionalCookiesDetails()">
                    <h1 class="headline">
                      {{ $t("cookies.cookieSettings.functionalCookies.title") }}
                    </h1>
                  </div>
                  <div class="col-4 d-flex justify-content-between justify-content-md-end align-items-center">
                    <i
                      class="bb-arrow-down mr-md-5 cursor-pointer"
                      :class="{ opened: functionalCookieDetailsOpened }"
                      @click="toggleFunctionalCookiesDetails()" />
                    <switches
                      v-model="functionalCookiesEnabled"
                      theme="bootstrap"
                      type-bold="true"
                    ></switches>
                  </div>
                </div>
              </div>
              <div
                v-if="functionalCookieDetailsOpened"
                class="col-12 mt-4 mb-4 text"
              >
                {{ $t("cookies.cookieSettings.functionalCookies.content") }}
              </div>
              <div class="col-12">
                <hr style="border: 1px solid rgba(75, 46, 170, 0.2)" />
              </div>
              <div class="col-12">
                <div class="row headline-row">
                  <div class="col-8 cursor-pointer" @click="toggleFunctionalCookiesDetails()" >
                    <h1 class="headline">
                      {{ $t("cookies.cookieSettings.thirdPartyCookies.title") }}
                    </h1>
                  </div>
                  <div class="col-4 d-flex justify-content-between justify-content-md-end align-items-center">
                    <i
                      class="bb-arrow-down mr-md-5 cursor-pointer"
                      :class="{ opened: thirdPartyCookieDetailsOpened }"
                      @click="toggleThirdPartyCookiesDetails()"
                    ></i>
                    <switches
                      v-model="thirdPartyCookiesEnabled"
                      theme="bootstrap"
                      type-bold="true"
                    ></switches>
                  </div>
                </div>
              </div>
              <div
                v-if="thirdPartyCookieDetailsOpened"
                class="col-12 mt-4 mb-4 text"
              >
                {{ $t("cookies.cookieSettings.thirdPartyCookies.content") }}
              </div>
              <div class="col-12">
                <hr style="border: 1px solid rgba(75, 46, 170, 0.2)" />
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <h1 class="headline">
                      {{ $t("cookies.cookieSettings.cookieDetails.title") }}
                    </h1>
                    <ul class="text">
                      <!-- <li>Google Analytics</li> -->
                      <li>Google Tag Manager</li>
                      <li>Hotjar</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <hr style="border: 1px solid rgba(75, 46, 170, 0.2)" />
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-6 d-flex align-items-center">
                    <a class="mr-2 d-flex align-items-center legal-notice-link" href="/legal-notice">{{
                      $t("cookies.cookieSettings.privacyPolicy")
                    }} <span class="arrow-right" />
                    </a>
                  </div>
                  <div
                    class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end mt-4 mt-md-0"
                  >
                    <base-button
                      class="modal-button"
                      :text="$t('cookies.cookieSettings.save')"
                      size="large"
                      @clicked="save()"
                    ></base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "../../elements/Button.vue";
import Switches from "vue-switches";

export default {
  name: "CookieSettings",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: { BaseButton, Switches },
  data: () => ({
    functionalCookiesEnabled: true,
    functionalCookieDetailsOpened: false,
    thirdPartyCookiesEnabled: false,
    thirdPartyCookieDetailsOpened: false,
  }),
  mounted() {
    this.setSwitchesDefaultState(this.$cookies.get("cookie_status"));

    if (this.show) {
      $("#cookieSettings").modal("show");
    } else {
      $("#cookieSettings").modal("hide");
    }
  },
  watch: {
    show: function () {
      if (this.show) {
        $("#cookieSettings").modal("show");
        document.querySelector('body').classList.add('cookie-settings-opened');
        document.querySelector('.modal-backdrop').classList.add('cookie-settings-opened');
      } else {
        $("#cookieSettings").modal("hide");
        document.querySelector('body').classList.remove('cookie-settings-opened');
        document.querySelector('.modal-backdrop').classList.remove('cookie-settings-opened');
      }
    },
  },
  computed: {},
  methods: {
    save: function () {
      this.$emit("onSave", {
        functionalCookiesEnabled: this.functionalCookiesEnabled,
        thirdPartyCookiesEnabled: this.thirdPartyCookiesEnabled,
      });
    },
    toggleFunctionalCookiesDetails: function () {
      this.functionalCookieDetailsOpened = !this.functionalCookieDetailsOpened;
    },
    toggleThirdPartyCookiesDetails: function () {
      this.thirdPartyCookieDetailsOpened = !this.thirdPartyCookieDetailsOpened;
    },
    setSwitchesDefaultState(settings) {
      switch(settings) {
        case "none":
          this.functionalCookiesEnabled = false;
          this.thirdPartyCookiesEnabled = false;
          break;
        case "all":
          this.functionalCookiesEnabled = true;
          this.thirdPartyCookiesEnabled = true;
          break;
        case "functional":
          this.functionalCookiesEnabled = true;
          this.thirdPartyCookiesEnabled = false;
          break;
        case "third_party":
          this.functionalCookiesEnabled = false;
          this.thirdPartyCookiesEnabled = true;
          break;
        default:
          this.functionalCookiesEnabled = true;
          this.thirdPartyCookiesEnabled = false;
          break;
      }
    }
  },
};
</script>
<style lang='scss' scoped>
// TODO: rewrite this!!! 
// @import "bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.text {
  color: $text-blue;
}

.modal-dialog {
  @media (min-width: map_get($grid-breakpoints, "sm")) {
    max-width: map_get($grid-breakpoints, "sm") !important;
  }

  @media (min-width: map_get($grid-breakpoints, "md")) {
    max-width: map_get($grid-breakpoints, "md") !important;
  }

  @media (min-width: map_get($grid-breakpoints, "lg")) {
    max-width: map_get($grid-breakpoints, "lg") !important;
  }

  @media (min-width: map_get($grid-breakpoints, "xl")) {
    max-width: map_get($grid-breakpoints, "xl") !important;
  }

  .modal-content {
    border-radius: 10px;
    .content {
      margin-bottom: 10px;
    }
  }

  .opened {
    transform: rotate(180deg);
  }
}

.rotated {
  transform: rotate(-90deg);
}

.cursor-pointer {
  cursor: pointer;
}

.arrow-right {
  display: inline-block;
  background-color: $primary-purple;
  mask-image: url("/images/icons/bb-icon_arrow-right.svg");
  mask-size: cover;
  mask-repeat: no-repeat;
  width: 10px;
  height: 17px;
  margin: 0 0 0 10px;
  transition: $transition-global;
}

.legal-notice-link:hover span {
  background-color: $link-hover-color;
}

.vue-switcher {
  margin-bottom: 0;

  &::v-deep {
    div {
      top: 0;
      background-color: $switcher-background-color;

      &:after {
        box-shadow: none;
        background-color: $white;
      }
    }
  }

  &.vue-switcher--unchecked {
    &::v-deep {
      div {
        background-color: $switcher-unchecked-background-color;
        &:after {
          background-color: $white;
        }
      }

    }
  }
}
</style>