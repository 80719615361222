import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

import settings from "@/store/modules/settings";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    settings
  },
  plugins: [
    persistedState({
      key: "store",
      paths: ["settings"]
    })
  ]
});

export default store;
