<template>
  <div id="main-navbar">
    <large-navbar
      :languageOptions="languageOptions"
      :defaultLanguage="selectedLanguage"
      :navigations="whiteListedNavigations"
      class="d-none d-md-block"
    />
    <mobile-navbar
      :languageOptions="languageOptions"
      :defaultLanguage="selectedLanguage"
      :navigations="whiteListedNavigations"
      class="d-md-none d-xs-block d-sm-block"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LargeNavbar from "./LargeNavbar";
import MobileNavbar from "./MobileNavbar";

export default {
  name: "MainNavbar",
  props: {},
  components: {
    LargeNavbar,
    MobileNavbar,
  },
  data: () => ({
    navigations: [
      {
        titleKey: "globals.navigation.products",
        subItems: [
          {
            titleKey: "globals.products.eBlockStock.title",
            descriptionKey: "globals.products.eBlockStock.description",
            link: "/products/ebso",
          },
          {
            titleKey: "globals.products.blockNote.title",
            descriptionKey: "globals.products.blockNote.description",
            link: "/products/bno",
          },
          {
            titleKey: "globals.products.blockNotex.title",
            descriptionKey: "globals.products.blockNotex.description",
            link: "/products/bnox",
          },
          {
            titleKey: "globals.products.holoverz.title",
            descriptionKey: "globals.products.holoverz.description",
            link: "/products/holoverz",
          },
        ],
      },
      {
        titleKey: "globals.navigation.prices",
        link: "/prices",
      },
      {
        titleKey: "globals.navigation.wallet",
        link: "/wallet",
      },
      {
        titleKey: "globals.navigation.aboutUs",
        link: "/about-us",
      },
      {
        titleKey: "globals.navigation.joinPlatform",
        link: "/joinplatform",
      },
      {
        titleKey: "globals.navigation.holoverzCollections",
        link: "/holoverz-collections",
      },
      // TODO: temporary remove
      // {
      //   titleKey: "globals.navigation.support",
      //   link: "/support",
      // },
    ],
    selectedLanguage: undefined,
  }),
  created() {
    this.setSelectedLanguage();
  },
  computed: {
    ...mapGetters({
      getSelectedLanguage: "settings/getSelectedLanguage",
    }),
    languageOptions() {
      const options = [];
      this.$enums.supportedLanguages.forEach((element) => {
        const helper = {};
        helper.value = element.value;
        helper.label = this.$t(element.label);
        helper.shortLabel = this.$t(element.shortLabel);
        options.push(helper);
      });
      return options;
    },
    whiteListedNavigations() {
      let enabledRoutes = []
      this.$router.options.routes.forEach(item => {
        if (item.children.length > 0) {
          return enabledRoutes.push(...item.children.map(subitem => subitem.path))
        }
      })

      let filteredNavigations = this.navigations.reduce((result, item) => {
        if (item.subItems) {
          let tmp = {...item}
          tmp.subItems = item.subItems.filter(subitem => enabledRoutes.includes(subitem.link.substring(1)))
          result.push(tmp)
        } else if (enabledRoutes.includes(item.link.substring(1))) {
          result.push(item)
        } else if (item.link.includes("http")) {
          result.push(item)
        }
        return result
      }, [])
      return filteredNavigations
    }
  },
  watch: {
    getSelectedLanguage: {
      immediate: true,
      handler() {
        this.setSelectedLanguage();
      },
    },
  },
  methods: {
    setSelectedLanguage() {
      if(this.getSelectedLanguage) {
        this.selectedLanguage = this.languageOptions.find((x) => {
          return x.value == this.getSelectedLanguage;
        });
      } else {
        this.selectedLanguage = this.languageOptions.find((x) => {
          return x.value == process.env.VUE_APP_I18N_FALLBACK_LANG_CODE;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

#main-navbar {
  position: fixed;
  width: 100vw;
  z-index: 2;
  background: white;
  top: 0;
  left: 0;
}
</style>
