<template>
  <div>
    <div id="app">
      <main-navbar ref="navbar" />
      <section class="router-view" :style="{ paddingTop: `${navbarHeight}px` }">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <router-view />
        </transition>
      </section>
      <Footer />
    </div>
    <cookie-accept-modal
      :show="showCookieAccept"
      @onShowSettings="showSettings()"
      @onAcceptCookies="acceptCookies()"
    ></cookie-accept-modal>
    <cookie-settings
      :show="showCookieSettings"
      @onSave="onSave($event)"
    ></cookie-settings>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainNavbar from "@/components/modules/navbars/MainNavbar";
import Footer from "@/components/modules/footer/Footer";
import CookieAcceptModal from "./components/modules/cookie-settings/CookieAcceptModal.vue";
import CookieSettings from "./components/modules/cookie-settings/CookieSettings.vue";

export default {
  name: "App",
  props: {},
  components: {
    MainNavbar,
    Footer,
    CookieAcceptModal,
    CookieSettings,
  },
  data: () => ({
    navbarHeight: 0,
    acceptedCookies: false,
    showCookieAccept: false,
    showCookieSettings: false,
  }),
  created() {
    this.$i18n.locale = this.getSelectedLanguage ? this.getSelectedLanguage : process.env.VUE_APP_I18N_FALLBACK_LANG_CODE;
    let cookiesStatus = this.$cookies.get("cookie_status");
    if (cookiesStatus === null) {
      this.showCookieAccept = true;
    }
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.navbarHeight = this.$refs.navbar.$el.clientHeight;
  },
  computed: {
    ...mapGetters({
      getSelectedLanguage: "settings/getSelectedLanguage",
    }),
  },
  methods: {
    handleResize() {
      this.navbarHeight = this.$refs.navbar.$el.clientHeight;
    },
    async showSettings() {
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      this.showCookieAccept = false;
      // FIXME Az első modal magassága érvényes a másodikra ezért mobilon nem látszik az alja ha nem tűnik el az első modal div mielőtt a második megjelenne.
      await sleep(330);
      this.showCookieSettings = true;
    },
    onSave(e) {
      // Settings object
      this.showCookieSettings = false;
      if(e.functionalCookiesEnabled && e.thirdPartyCookiesEnabled) {
        this.$cookies.set("cookie_status", "all", 60 * 60 * 24 * 30);
      } else if(e.functionalCookiesEnabled) {
        this.$cookies.set("cookie_status", "functional", 60 * 60 * 24 * 30);
      } else if(e.thirdPartyCookiesEnabled) {
        this.$cookies.set("cookie_status", "third_party", 60 * 60 * 24 * 30);
      } else {
        this.$cookies.set("cookie_status", "none", 60 * 60 * 24 * 30);
      }

      setTimeout(() => {
        location.reload();
      }, 100);
    },
    acceptCookies() {
      this.showCookieAccept = false;
      this.acceptedCookies = true;
      this.$cookies.set("cookie_status", "all", 60 * 60 * 24 * 30);

      setTimeout(() => {
        location.reload();
      }, 300);
    },
  },
};
</script>

<style lang="scss">
#app {
  display: grid;
  grid-template-rows: minmax(100vh, 100%) auto;
  grid-template-columns: 100%;
}
</style>
