import i18n from "@/lang/i18n";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtm from '@gtm-support/vue2-gtm';
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/src/jquery.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/global.scss";
import VueResizeObserver from "vue-resize-observer";
import VueCookies from 'vue-cookies';
import Hotjar from 'vue-hotjar';
import globalHelpers from './helpers/globalHelpers'

//  GLOBAL COMPONENTS
import "@/assets/js/globalComponents";

//  Anime JS for animation
import anime from "animejs";

//  Importing enums
import enums from "@/components/mixins/enums";

// Simple global event bus
Vue.prototype.$eventBus = new Vue();

// Google TagManager + Hotjar
let isThirdPartyToolsEnabled = $cookies.get("cookie_status") == "all" || $cookies.get("cookie_status") == "third_party";

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  enabled: isThirdPartyToolsEnabled,
});

Vue.use (Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: isThirdPartyToolsEnabled 
});

// Global helpers
const plugin = {
  install() {
    Vue.helpers = globalHelpers
    Vue.prototype.$helpers = globalHelpers
  }
}

Vue.use(plugin)

Vue.prototype.$enums = enums;
Vue.prototype.$anime = anime;
Vue.config.productionTip = false;
Vue.use(VueResizeObserver);
Vue.use(VueCookies);
window.$ = window.jQuery = require('jquery');

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
