import i18n from '@/lang/i18n';

const mutations = {
  SET_SELECTED_LANGUAGE(state, language) {
    state.selectedLanguage = language;
    i18n.locale = language;
  },
};

export default mutations;
