<template>
  <div id="mobile-navbar-menu">
    <div class="close-section d-flex justify-content-end">
      <div class="close-icon" @click="closeMenu">
        <span class="icon" />
      </div>
    </div>
    <section class="content-section">
      <template v-for="(item, i) in navigations">
        <template v-if="item.subItems">
          <accordion :key="i" class="accordion" :maxHeight="420">
            <template v-slot:header>
              <div class="header-title headline-l text-bold">
                {{ $t(item.titleKey) }}
              </div>
            </template>
            <ul class="accordion-pages">
              <template v-for="(page, i) in item.subItems">
                <li :key="i" class="accordion-pages-item">
                  <router-link
                    v-if="!page.link.includes('https')"
                    :to="page.link"
                    class="link headline"
                    :class="page.titleKey == 'globals.products.bit5.title' || page.titleKey == 'globals.products.bit20.title' ? 'text-grey' : 'text-white'"
                    >{{ $t(page.titleKey) }}</router-link
                  >
                  <a
                    v-else
                    :href="page.link"
                    class="headline"
                    target="_blank"
                    >{{ $t(page.titleKey) }}</a
                  >
                </li>
              </template>
            </ul>
          </accordion>
        </template>
        <template v-else>
          <section :key="i" class="menu-item">
            <router-link
              v-if="!item.link.includes('https')"
              :to="item.link"
              class="menu-link text-white headline-l text-bold"
              >{{ $t(item.titleKey) }}</router-link>
              <a
                v-else
                :href="item.link"
                class="menu-link text-white headline-l text-bold"
                target="_blank"
                >{{ $t(item.titleKey) }}</a>
          </section>
        </template>
      </template>
      <section class="language-selector-section">
        <accordion :maxHeight="130">
          <template v-slot:header>
            <div class="header">
              <i class="bb-sphere button-icon"></i>
              <div class="header-title headline text-bold">
                {{ $t(selectedLanguage.label) }}
              </div>
            </div>
          </template>
          <ul class="language-selector">
            <template v-for="(lang, i) in languageOptions">
              <li
                :key="i"
                class="language-selector-item d-flex justify-content-between"
                :class="{ active: lang == selectedLanguage }"
                @click="languageSelected(lang)"
              >
                {{ $t(lang.label) }}
                <i
                  :key="i"
                  v-if="lang == selectedLanguage"
                  class="pipe bb-pipe"
                ></i>
              </li>
            </template>
          </ul>
        </accordion>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MobileNavbarMenu",
  props: {
    defaultLanguage: {
      type: Object,
      required: false,
    },
    languageOptions: {
      type: Array,
      required: false,
    },
    navigations: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data: () => ({
    selectedLanguage: undefined,
  }),
  created() {},
  watch: {
    defaultLanguage: {
      immediate: true,
      handler() {
        this.selectedLanguage = this.defaultLanguage;
      },
    },
    $route() {
      this.$emit("closeMenu");
    },
  },
  methods: {
    ...mapActions({
      setSelectedLanguage: "settings/setSelectedLanguage",
    }),
    closeMenu() {
      this.$emit("closeMenu");
    },
    languageSelected(lang) {
      this.selectedLanguage = lang;
      this.setSelectedLanguage(lang.value);
      this.$router.push({ name: this.$route.name, params: { lang: lang.value } })
    },
  },
};
</script>

<style lang="scss" scoped>
#mobile-navbar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #4b2eaa;
  z-index: 2;
  padding: 0 15px 0 20px;
  overflow: auto;
  .close-section {
    height: 60px;
    .close-icon {
      margin-top: 30px;
      height: 20px;

      .icon {
        display: inline-block;
        cursor: pointer;
        background-color: $white;
        width: 19px;
        height: 19px;
        mask-image: url("/images/icons/bb-icon_times.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
      }
    }
  }
  .content-section {
    margin-top: calc(115px - 60px);
    color: white;
    .accordion {
      margin-bottom: 41px;
      &::v-deep .toggle-arrow {
        background-color: $white !important;
      }
      .accordion-pages {
        list-style-type: none;
        padding-left: calc(62px - 15px);
        margin: 0;
        .accordion-pages-item {
          margin-bottom: $margin-s;
          &:first-of-type {
            margin-top: $margin-s;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          .link {
            font-family: "HKNova-Bold";
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .menu-item {
      margin-bottom: $margin-s;
      &:last-of-type {
        margin-bottom: 0;
      }
      .menu-link {
        font-family: "HKNova-Bold", sans-serif;
      }
    }
    .language-selector-section {
      margin-bottom: $margin-s;
      .header {
        display: flex;
        align-items: center;
        .button-icon {
          font-size: $headline;
          padding-right: 10px;
          vertical-align: middle;
          display: inline-block;
        }
      }
      .language-selector {
        list-style-type: none;
        padding-left: calc(62px - 15px);
        margin: 0;
        &-item {
          cursor: pointer;
          margin-bottom: $margin-s;
          font-family: "HKNova-Bold", sans-serif;
          // font-size: $text-l;
          font-size: 20px;
          &:first-of-type {
            margin-top: $margin-s;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          &.active {
            color: #00dda9;
          }
        }
      }
    }
  }
}

.text-grey {
  color: grey;
}

.text-white {
  color: white;
}
</style>
