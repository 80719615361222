const enums = {
  supportedLanguages: [
    {
      value: 'hu',
      label: 'globals.languages.hu.label',
      shortLabel: 'globals.languages.hu.shortLabel'
    },
    {
      value: 'en',
      label: 'globals.languages.en.label',
      shortLabel: 'globals.languages.en.shortLabel'
    }
  ]
}

export default enums;
