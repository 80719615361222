import Vue from "vue";

Vue.component("BaseButton", () => import("@/components/elements/Button"));
Vue.component("DropdownButton", () =>
  import("@/components/elements/DropdownButton")
);
Vue.component("Accordion", () => import("@/components/elements/Accordion"));
Vue.component("Card", () => import("@/components/modules/blog/Card"));
Vue.component("Jumbotron", () => import("@/components/elements/Jumbotron"));
Vue.component("StoreButton", () => import("@/components/elements/StoreButton"));
